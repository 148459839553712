<script lang="ts" setup></script>

<template>
  <UBadge
    :ui="{
      size: {
        sm: 'text-xs px-2 py-1',
      },
      rounded: 'rounded-full',
      variant: {
        solid: 'text-gray-600 dark:text-gray-300 dark:bg-gray-600/75 bg-gray-100',
      },
    }"
  >
    <slot />
  </UBadge>
</template>
